<template lang="html">
  <b-card :title="$t('search.searchProducts.header')">
    <SearchProducts
      :start-date="start"
      :end-date="end"
      :type="type"
      :download="$t('search.searchProducts.download')"
    />
  </b-card>
</template>

<script>
import SearchProducts from '@/views/components/search/Report/SearchProducts.vue'
import store from '@/store'

export default {
  components: { SearchProducts },
  computed: {
    start() {
      return store.state.search.dateRange.start
    },
    end() {
      return store.state.search.dateRange.end
    },
    type() {
      if (store.state.search.activeDataSource === null) {
        return 'all'
      }
      return store.state.search.activeDataSource.value
    },
  },
}
</script>

<style scoped>
.vs-pagination--ul {
  margin-bottom: 0;
}

.report-table table th:not(:first-child):not(:nth-child(2)) .vs-table-text {
  text-align: right;
  display: block;
}
</style>
